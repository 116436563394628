<template>
        <v-card :color="this.chartProp.color1">
            <v-row class="no-gutters">
                <v-col cols="1"  :class="this.chartProp.color2">
                </v-col>
                <v-col cols="2" class="pa-2">
                    <br>
                    <v-icon x-large color="white">{{this.chartProp.icon}}</v-icon>
                </v-col>
                
                    <v-col cols="8" class="pa-2 text-right white--text">
                    {{this.chartProp.title}}
                    <h1 class="white--text text-right">{{this.chartProp.value}}</h1>
                </v-col>                                            
            </v-row>
        </v-card>
</template>

<script>
export default {
    name: "BoxChart",
    props: {
        chartProp:Object
    },      
    components:{

    },
    computed: {
    },
        data() {
            return{
            }
        },
        created(){
           
        },
}    

</script>