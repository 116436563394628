import axios from "axios"
let vlogin_id = localStorage.getItem('user_login_id');
class MpDashboardService {

    
    view_dashboard(form) {
        //console.log("apdashboardservices local storage",localStorage.getItem('token_auth'));
        axios.defaults.headers.common['Token-Auth'] = localStorage.getItem('mtoken_auth');
        form.user_login_id = vlogin_id;
        let response = axios.post("mp_dashboard/view_dashboard", form);
        return response;

    }



}

    
 

export default new MpDashboardService();