<template>
    <v-card>
        <v-card-title>{{this.chartProp.title}}</v-card-title>
        <div id="pchart">
            <apexchart type="pie" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>					
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "PieChart",
    props: {
        chartProp:Object
    },      
    components:{
        apexchart: VueApexCharts,
    },
    computed: {
    },
        data() {
            return{
                series: [44, 55, 13, 43, 22],
                chartOptions: {

                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
            }
        },
        created(){
            
            this.series = this.chartProp.series;
            this.chartOptions.labels = this.chartProp.labels;
        },
}    

</script>