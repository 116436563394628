<template>
    <v-card>
        <div id="lchart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>					
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "LineChart",
    props: {
        chartProp:Object
    },      
    components:{
        apexchart: VueApexCharts,
    },
    computed: {
    },
        data() {
            return{
                series: [{
                    name: "Desktops",
                    data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
                }],
            
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Product Trends by Month',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                }
            },
            }
        },
        created(){
            
            this.chartOptions.title.text = this.chartProp.title;
            this.series = this.chartProp.series;
            this.chartOptions.xaxis.categories = this.chartProp.xcategories;
            this.chartOptions.yaxis.title.text = this.chartProp.yaxisTitle;
        },
}    

</script>