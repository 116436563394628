<template>
    <v-card>
        <v-card-title>{{this.chartProp.title}}</v-card-title>
        <v-data-table
            ref="vuetable"
            :headers="this.chartProp.tblHeaders"
            :items="this.chartProp.tblBody"
        ></v-data-table>
        <v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "TableChart",
    props: {
        chartProp:Object
    },      
    components:{

    },
    computed: {
    },
        data() {
            return{
            }
        },
        created(){
           
        },
}    

</script>